"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenIframeController = void 0;
var MediaQueryManager_1 = require("common/MediaQueryManager");
var TokenModalController_1 = require("./TokenModalController");
var modal_1 = require("constants/modal");
var TokenIframeController = /** @class */ (function (_super) {
    __extends(TokenIframeController, _super);
    function TokenIframeController(_a) {
        var url = _a.url, containerElement = _a.containerElement;
        var mediaQueryManager = new MediaQueryManager_1.MediaQueryManager();
        var appearanceMode = mediaQueryManager.getPreferredColorScheme();
        var backgroundColor = appearanceMode === modal_1.APPEARANCE_MODES.DARK ? modal_1.BG_COLOR.DARK : modal_1.BG_COLOR.LIGHT;
        return _super.call(this, {
            url: url,
            containerElement: containerElement,
            attrs: {
                style: {
                    position: "static",
                    display: "inline",
                    height: "650px",
                    maxWidth: "700px",
                    backgroundColor: backgroundColor,
                    borderRadius: "4px",
                },
            },
        }) || this;
    }
    return TokenIframeController;
}(TokenModalController_1.TokenModalController));
exports.TokenIframeController = TokenIframeController;
