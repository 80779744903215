"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenManager = void 0;
var uaParser = require("ua-parser-js");
var TokenOverlayController_1 = require("./TokenOverlayController");
var TokenModalController_1 = require("./TokenModalController");
var popup_1 = require("constants/popup");
var modal_1 = require("constants/modal");
var Utils_1 = require("common/Utils");
var Messenger_1 = require("common/Messenger");
var TokenIframeController_1 = require("./TokenIframeController");
var isDesktop = !uaParser().device.type;
var TokenManager = /** @class */ (function () {
    function TokenManager(_a) {
        var _this = this;
        var url = _a.url, onDone = _a.onDone, onError = _a.onError, appUrl = _a.appUrl, sdkVersion = _a.sdkVersion;
        this._id = Utils_1.Utils.generateNonce();
        this.navigateToUrl = function (url) {
            // updated the src of modal iframe after the session id is being generated
            if (_this.webappIframe) {
                _this.webappIframe.updateAttrs({ src: url });
            }
            else if (!isDesktop) {
                window.location.replace(url);
            }
            else {
                console.log('Error navigateToUrl: can\'t navigate to URL in modal');
            }
        };
        this.cleanUp = function () {
            // destroy the modal
            if (_this.webappIframe)
                _this.webappIframe.destroy();
            if (_this.overlay)
                _this.overlay.destroy();
        };
        this._bindEvents = function () {
            var messenger = _this._getMessengerInstance();
            messenger.onMessage(popup_1.POPUP_EVENTS.BANK_POPUP_CLOSE, function (_a) {
                var data = _a.data;
                if (_this.webappIframe) {
                    _this.webappIframe.updateAttrs({ src: data });
                }
            }, true);
            messenger.onMessage(modal_1.MODAL_EVENTS.SET_MODAL_STYLE, function (_a) {
                var data = _a.data;
                if (_this.webappIframe) {
                    _this.webappIframe.updateAttrs(data);
                }
            }, true);
            messenger.onMessage(popup_1.POPUP_EVENTS.BANK_POPUP_VISIBILITY_CHANGE, function (_a) {
                var visible = _a.visible;
                if (_this.overlay && !visible) {
                    _this.overlay.destroy();
                }
            }, true);
            messenger.onMessage(modal_1.MODAL_EVENTS.MODAL_CLOSE, function (_a) {
                var data = _a.data;
                if (_this.overlay) {
                    _this.overlay.destroy();
                }
            }, true);
            messenger.onMessage(modal_1.MODAL_EVENTS.MODAL_CLOSE_SUCCESS, function (_a) {
                var data = _a.data;
                if (_this.overlay) {
                    _this.overlay.destroy();
                }
                _this._onSuccessCb(data);
            }, true);
            messenger.onMessage(modal_1.MODAL_EVENTS.MODAL_CLOSE_FAILURE, function (_a) {
                var data = _a.data;
                if (_this.overlay) {
                    _this.overlay.destroy();
                }
                _this._onFailureCb(data);
            }, true);
        };
        this._getMessengerInstance = function () {
            var url = _this._url;
            var id = _this._id;
            if (!_this._messenger) {
                _this._messenger = new Messenger_1.Messenger({
                    id: id || "",
                    source: window,
                    destination: _this.webappIframe,
                    origin: url,
                });
            }
            return _this._messenger;
        };
        this._url = url;
        this._appUrl = appUrl;
        this._sdkVersion = sdkVersion;
        this._onSuccessCb = onDone;
        this._onFailureCb = onError;
    }
    TokenManager.prototype.initApp = function (_a) {
        var _b = _a === void 0 ? { appUrl: this._appUrl } : _a, appUrl = _b.appUrl;
        // initialize the modal with loading screen until the sessionid is being generated 
        if (isDesktop) {
            this.overlay = new TokenOverlayController_1.TokenOverlayController({ url: this._url, version: this._sdkVersion });
            this.overlay.show();
            this.webappIframe = new TokenModalController_1.TokenModalController({
                url: appUrl || this._appUrl,
            });
            this._bindEvents();
            this.webappIframe.show();
        }
        return this;
    };
    TokenManager.prototype.initAppIframe = function (_a) {
        var parentEl = _a.parentEl, appUrl = _a.appUrl;
        var url = appUrl || this._appUrl;
        this.webappIframe = new TokenIframeController_1.TokenIframeController({
            url: url,
            containerElement: parentEl,
        });
        this._bindEvents();
        this.webappIframe.show();
        return this;
    };
    return TokenManager;
}());
exports.TokenManager = TokenManager;
