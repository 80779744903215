"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenOverlayController = void 0;
var BaseIframeController_1 = require("./BaseIframeController");
var TokenOverlayController = /** @class */ (function (_super) {
    __extends(TokenOverlayController, _super);
    function TokenOverlayController(_a) {
        var url = _a.url, version = _a.version;
        var src = url + "/".concat(version, "/overlay.html");
        return _super.call(this, {
            src: src,
            name: "token-overlay-iframe",
            attrs: {
                style: {
                    position: "fixed",
                    display: "inline",
                    zIndex: "9999",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    backgroundColor: "rgba(0, 0, 0, 0.82)",
                },
            },
        }) || this;
    }
    return TokenOverlayController;
}(BaseIframeController_1.BaseIframeController));
exports.TokenOverlayController = TokenOverlayController;
